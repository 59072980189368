import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/org/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Beadswap is an Decentralized protocol for `}<em parentName="p">{`automated liquidity`}</em>{` based on `}<Link to="/docs/v1/protocol-overview/glossary/#constant-product-formula" mdxType="Link">{`constant product formula`}</Link>{`
and implemented with non-upgradeable smart contracts on the `}<a parentName="p" {...{
        "href": "https://ethereum.org/"
      }}>{`Ethereum`}</a>{` blockchain.
It removes the need for trusted intermediaries, prioritizing `}<strong parentName="p">{`decentralization`}</strong>{`, `}<strong parentName="p">{`censorship resistance`}</strong>{`,
and `}<strong parentName="p">{`security`}</strong>{`. Beadswap is `}<strong parentName="p">{`open-source software`}</strong>{` licensed under the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GNU_General_Public_License"
      }}>{`GPL`}</a>{`.`}</p>
    <p>{`Anybody can turn into a liquidity supplier for a pool by storing an equivalent value of each basic token as a trade-off for pool tokens. These tokens track LP shares of the complete reserves and can be recovered for the underlying resources whenever.`}</p>
    <p>{`Each Beadswap smart contract, or pair, manages a liquidity pool made up of reserves of two `}<a parentName="p" {...{
        "href": "https://eips.ethereum.org/EIPS/eip-20"
      }}>{`ERC-20`}</a>{` tokens.`}</p>
    <p>{`Pairs act as automated market makers, standing ready to accept one token for the other as long as the “constant product” formula is preserved. This formula, most simply expressed as `}<inlineCode parentName="p">{`x * y = k`}</inlineCode>{`, states that trades must not change the product (`}<inlineCode parentName="p">{`k`}</inlineCode>{`) of a pair’s reserve balances (`}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{`). Because `}<inlineCode parentName="p">{`k`}</inlineCode>{` remains unchanged from the reference frame of a trade, it is often referred to as the invariant. This formula has the desirable property that larger trades (relative to reserves) execute at exponentially worse rates than smaller ones.`}</p>
    <p>{`In practice, Beadswap applies a 0.30% fee to trades, which is added to reserves. As a result, each trade actually increases `}<inlineCode parentName="p">{`k`}</inlineCode>{`. This functions as a payout to LPs, which is realized when they burn their pool tokens to withdraw their portion of total reserves. In the future, this fee may be reduced to 0.25%, with the remaining 0.05% withheld as a protocol-wide charge.`}</p>
    <p>{`Because the relative price of the two pair assets can only be changed through trading, divergences between the Beadswap price and external prices create arbitrage opportunities. This mechanism ensures that Beadswap prices always trend toward the market-clearing price.`}</p>
    <h1 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Further reading`}</h1>
    <p>{`To see how token swaps work in practice, and to walk through the lifecycle of a swap, check out `}<Link to="/docs/v1/core-concepts/swaps" mdxType="Link">{`Swaps`}</Link>{`. Or, to see how liquidity pools work, see `}<Link to="/docs/v1/core-concepts/pools" mdxType="Link">{`Pools`}</Link>{`.`}</p>
    <p>{`Ultimately, of course, the Beadswap protocol is just smart contract code running on Ethereum. To understand how they work, head over to `}<Link to="/docs/v1/protocol-overview/smart-contracts/" mdxType="Link">{`Smart Contracts`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      